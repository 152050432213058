.navbar-custom {
    background-color: #282c34;
}

.navbar-custom .nav-item .nav-link.active {
    color: black !important;
}
  
.navbar-custom .nav-item .nav-link {
    color: white !important;
}

.App-logo {
    height: 20vmin;
    pointer-events: none;
}

.modal-header-success {
    background-color: #98FB98;
}

.modal-header-error {
    background-color: #E03C31;
}

.modal-body-colour {
    background-color: #DCDCDC;
}