.attendance-popup {
    position: fixed;
    bottom: 20px;
    left: 20px;
    background-color: #f5f82d;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: 999;
}

.attendance-popup-heading {
    font-size: 20px;
    margin-bottom: 10px;
}

.attendance-count {
    font-size: 36px;
    font-weight: bold;
    text-align: center;
}
