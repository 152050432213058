body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.navbar-custom {
    background-color: #282c34;
}

.navbar-custom .nav-item .nav-link.active {
    color: black !important;
}
  
.navbar-custom .nav-item .nav-link {
    color: white !important;
}

.App-logo {
    height: 20vmin;
    pointer-events: none;
}

.modal-header-success {
    background-color: #98FB98;
}

.modal-header-error {
    background-color: #E03C31;
}

.modal-body-colour {
    background-color: #DCDCDC;
}
#PIN, #newPIN {
    -webkit-text-security: disc;
}
.appName {
	background-color: 282c34;
	font-size: x-large;
	color: whitesmoke;
}

.attendance-popup {
    position: fixed;
    bottom: 20px;
    left: 20px;
    background-color: #f5f82d;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: 999;
}

.attendance-popup-heading {
    font-size: 20px;
    margin-bottom: 10px;
}

.attendance-count {
    font-size: 36px;
    font-weight: bold;
    text-align: center;
}

#PIN {
    -webkit-text-security: disc;
}
